<template>
  <div class="doctor">
   <h1>PT: {{doctor.firstName}} {{doctor.lastName}}</h1> 
   <form @submit.prevent="save">
      Zoom Id: <input type="text" v-model="doctor.zoomId"><br/>

      <br/>

      <h2>Offices</h2>
      <a @click="pickOffice_Click">+ Add office</a>
      <br/><br/>
      <b-modal id="pickOfficeModal" title="Select an office" hide-footer="true">
        <b-form-input v-model="filter" type="search" placeholder="Type to Search" :debounce="500"></b-form-input>
        <b-table show-empty striped hover 
          :items="this.fetchData"
          :fields="fields" 
          :current-page="currentPage"
          :per-page="10"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @row-clicked="rowClicked" 
        ></b-table>
        <b-pagination pills
          size="sm"
          v-model="currentPage"
          :total-rows="totalItemsCount"
          :per-page="perPage"
        ></b-pagination>

      </b-modal>

      <div v-for="doctorOffice in doctorOffices" :key="doctorOffice.officeId">
          {{doctorOffice.officeName}} <a @click="removeOffice_Click(doctorOffice.officeId)"> - Remove</a>
          <br />
          <input type="checkbox" v-model="doctorOffice.mondayIsWorking" />Monday &nbsp;<input type="textbox" v-model="doctorOffice.mondayHours" /><br />
          <input type="checkbox" v-model="doctorOffice.tuesdayIsWorking" />Tuesday &nbsp;<input type="textbox" v-model="doctorOffice.tuesdayHours" /><br />
          <input type="checkbox" v-model="doctorOffice.wednesdayIsWorking" />Wednesday &nbsp;<input type="textbox" v-model="doctorOffice.wednesdayHours" /><br />
          <input type="checkbox" v-model="doctorOffice.thursdayIsWorking" />Thursday &nbsp;<input type="textbox" v-model="doctorOffice.thursdayHours" /><br />
          <input type="checkbox" v-model="doctorOffice.fridayIsWorking" />Friday &nbsp;<input type="textbox" v-model="doctorOffice.fridayHours" /><br />
          <input type="checkbox" v-model="doctorOffice.saturdayIsWorking" />Saturday &nbsp;<input type="textbox" v-model="doctorOffice.saturdayHours" /><br />
          <input type="checkbox" v-model="doctorOffice.sundayIsWorking" />Sunday &nbsp;<input type="textbox" v-model="doctorOffice.sundayHours" /><br />
          <br /><br />
      </div>

      <button type="submit" class="small">Save</button>
    </form>
  </div>
</template>

<script>
import doctorsService from '../services/doctors';
import officesService from '../services/offices';

export default {
  name: 'Doctor',
  props: ["id"],
  data: () => {
    return {
      doctor: null,
      doctorOffices: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'isVirtualOnly',
          label: 'Virtual Only',
          sortable: true
        },
        {
          key: 'privateRoomsCount',
          label: 'Private Rooms',
          sortable: true
        },
        {
          key: 'city',
          label: 'City',
          sortable: true
        },
        {
          key: 'state',
          label: 'State',
          sortable: true
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItemsCount: 0,
      filter: null,
      sortBy: null,
      sortDesc: false
    };
  },
  async mounted() {
    this.doctor = await doctorsService.getById(this.id);
    this.offices = await officesService.getList();
    this.doctorOffices = await doctorsService.getDoctorOfficesByDoctorId(this.id);
  },
  methods: {
    pickOffice_Click: function() {
      this.$bvModal.show("pickOfficeModal");
    },
    removeOffice_Click: function(id) {
      this.doctorOffices = this.doctorOffices.filter(item => item.officeId != id);
    },
    async fetchData(params) {
      if (!params) params = { currentPage: this.currentPage, perPage: this.perPage };
      params.exceptIds = this.doctorOffices.map(item => item.officeId);
      const list = await officesService.getList(params);
      this.totalItemsCount = list.totalItemsCount;
      return list.items;
    },
    async rowClicked(row) {
      this.doctorOffices.unshift({ 
        doctorId: this.doctorId,
        officeId: row.id,
        officeName: row.name,
        mondayIsWorking: true,
        tuesdayIsWorking: true,
        wednesdayIsWorking: true,
        thursdayIsWorking: true,
        fridayIsWorking: true,
        saturdayIsWorking: true,
        sundayIsWorking: true
      });
      this.$bvModal.hide("pickOfficeModal");
      await this.fetchData();
    },
    save: async function() {
      await doctorsService.save(this.doctor);
      await doctorsService.saveLinkedOffices(this.doctorOffices);
      this.$toastr.s("Saved");
    }
  }
}
</script>